import Routes from './routes/routes'
import './styles/style.scss'
import './styles/style.css'
import './styles/anime.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
	return (
		<>
			<Routes />
		</>
	)
}

export default App
